import React from 'react';
// import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';

function ContactUs(){
    
    return (
        <>
           <div className="section section-team text-center" id="contact-us-section">
                <Container>
                    <h2 className="title">Contact Us</h2>
                    <div>
                        <p className="text-info">Phone: (770) 617-8995</p>
                        <p className="text-info">Email: rumasdelights@someemail.com</p>
                        <div className="text-center">
                            <Button
                                className="btn-icon btn-round"
                                color="info"
                                href="https://www.facebook.com/rumasdelights"
                            >
                                <i className="fab fa-facebook-square"></i>
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ContactUs;