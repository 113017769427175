import React from 'react';

//reactstrap components
import {Container, Row, Col} from 'reactstrap';
import CommonCarousel from '../Elements/CommonCarousel.js';

//core components
function MainHeader(){
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
          const updateScroll = () => {
            let windowScrollTop = window.pageYOffset / 3;
            pageHeader.current.style.transform =
              "translate3d(0," + windowScrollTop + "px,0)";
          };
          window.addEventListener("scroll", updateScroll);
          return function cleanup() {
            window.removeEventListener("scroll", updateScroll);
          };
        }
    });
    
    return (
        <>
        <div className="page-header" id="home-section">
            <div
            className="page-header-image"
            style={{
                backgroundImage: "url(" + require("assets/img/carousel/Barfi.png") + ")",
            }}
            ref= {pageHeader}
            ></div>
            <div className="content">
                <Container>
                    <h4>&nbsp;</h4>
                    <h1 className="title">fresh and delicious</h1>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <CommonCarousel />
                        </Col>
                    </Row>
                    <h2 className="title">(770) 617-8995</h2>
                </Container>
            </div>
        </div>
        </>
    );
}

export default MainHeader;